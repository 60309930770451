import { onCLS, onINP, onLCP, onFCP, Metric } from "web-vitals/attribution";

/**
 * Sometimes the FCP metric is fired before Google Analytics has loaded, so we
 * need to do this ourselves manually. Note, you can't just push to window.ga4DataLayer
 * directly, nor can you use (...args) => window.ga4DataLayer.push(args). The code
 * that processes the data layer cares about the type being an IArguments object, and
 * rest parameters are a normal array.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function gtag(..._: unknown[]): void {
  window.ga4DataLayer = window.ga4DataLayer || [];
  window.ga4DataLayer.push(arguments); // eslint-disable-line prefer-rest-params
}

const track =
  <T extends Metric>(
    attribution: (
      metric: T,
    ) => Record<string, string | number | boolean | undefined>,
  ) =>
  (metric: T): void => {
    gtag("event", metric.name, {
      value: metric.delta,
      metric_id: metric.id,
      metric_value: metric.value,
      metric_delta: metric.delta,
      ...attribution(metric),
    });
  };

onCLS(
  track(({ attribution }) => ({
    load_state: attribution.loadState,
    target: attribution.largestShiftTarget,
  })),
);
onINP(
  track(({ attribution }) => ({
    target: attribution.eventTarget,
    type: attribution.eventType,
  })),
);
onLCP(
  track(({ attribution }) => ({
    element: attribution.element,
    start_time: attribution.lcpEntry?.startTime,
    load_time: attribution.lcpEntry?.loadTime,
    render_time: attribution.lcpEntry?.renderTime,
  })),
);
onFCP(
  track(({ attribution }) => ({
    time_to_first_byte: attribution.timeToFirstByte,
    first_byte_to_paint: attribution.firstByteToFCP,
  })),
);
